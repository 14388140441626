import React from 'react';
import { setOnboardingModal } from "../../store/generalActions";
import { connect } from "react-redux";
import styles from './Onboarding.module.scss';
import Carousel from './Carousel/Carousel';

const Onboarding = (props) => {
    return (
        <div className={styles.providerInfoModal}>
			<div className={styles.modalContainer}> 
                <div> <label onClick={() => props.setOnboardingModal()}>saltar</label></div>
                <div><h2>Infocovid.online</h2></div>
                <Carousel />
                <div> <button onClick={() => props.setOnboardingModal()}>¡Comencemos!</button> </div>
            </div>
        </div>
    )
}
  
export default connect(null, {setOnboardingModal })(Onboarding);
  