import axios from "axios";

const api = "https://api.covid.paisanos.io";
const test = "http://192.168.0.10:5000";
const test2 = "https://api.infocovidpaisanos.com";
const instance = axios.create({
  baseURL: test2
});

export default instance;
