import * as actionTypes from "./types";

const INITIAL_STATE = {
  loading: false,
  products: null,
  userLocation: {
    lat: null,
    lng: null
  },
  theme: null,
  sellPoints: null,
  sellPointSelected: null,
  showAlertModal: true,
  showHeaderHome: false,
  isSearching: false,
  showSellPointCard: false,
  showSearchModal: false,
  showProviderModal: false,
  showCongratsModal: false,
  showSearchPDVModal: false,
  isFromSearchPDV: false,
  showReqLoading: false,
  showOnboardingModal: true,
};

const setPDVSelected = (state, payload) => {
  let date = new Date();
  let day = date.getDay();

  let lastUpdated = payload.placesInfo.lastUpdated ? payload.placesInfo.lastUpdated.split("T")[0] + " " + payload.placesInfo.lastUpdated.split("T")[1].split(":")[0] + ":" + payload.placesInfo.lastUpdated.split("T")[1].split(":")[1] + "hs" : "";

  const popularTime = payload.placesInfo.popular_times[day];
  const address = payload.placesInfo.formatted_addres ? payload.placesInfo.formatted_addres.split(",")[0] : "";
  const actualTime = payload.placesInfo.actual_time;
  const location = payload.placesInfo.location;

  return {
    ...state,
    sellPointSelected: { ...payload.pdv, location, popularTime, address, actualTime, lastUpdated },
    showSellPointCard: true
  };
};

const setPDVSelectedFromSearch = (state, placesInfo) => {
  let date = new Date();
  let day = date.getDay();
  console.log(placesInfo);
  let lastUpdated = placesInfo.lastUpdated.split("T")[0] + " " + placesInfo.lastUpdated.split("T")[1].split(":")[0] + ":" + placesInfo.lastUpdated.split("T")[1].split(":")[1] + "hs";

  const popularTime = placesInfo.popular_times[day];
  const address = placesInfo.formatted_address.split(",")[0] + "," + placesInfo.formatted_address.split(",")[1];
  const actualTime = placesInfo.actual_time;
  return {
    ...state,
    sellPointSelected: { ...placesInfo, popularTime, address, actualTime, lastUpdated },
    isFromSearchPDV: true,
    showSellPointCard: true
  };
};

const checkSellPointProductStatus = sellPointProduct => {
  return sellPointProduct.filter(product => product.state.name === "Si");
};

const setSellPointsHandler = (state, sellPoints) => {
  let sellPointsMap = sellPoints.map(sellPoint => {
    let status;
    let checkedArray = checkSellPointProductStatus(sellPoint.sellPointProduct);
    if (checkedArray.length === sellPoint.sellPointProduct.length) {
      status = "FULL";
    } else if (checkedArray.length > 0) {
      status = "MID";
    } else {
      status = "NS";
    }

    return { ...sellPoint, status: status };
  });

  return {
    ...state,
    sellPoints: sellPointsMap
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case actionTypes.SET_SELLPOINTS:
      return setSellPointsHandler({ ...state }, action.payload);
    case actionTypes.SET_USER_LOCATION:
      return {
        ...state,
        userLocation: {
          ...action.payload
        }
      };
    case actionTypes.SET_PDV:
      return setPDVSelected({ ...state }, action.payload);
    case actionTypes.CLOSE_PDV:
      return {
        ...state,
        sellPointSelected: null,
        showSellPointCard: false,
        isFromSearchPDV: false
      };
    case actionTypes.SET_LOADING:
      return {
        ...state,
        loading: !state.loading
      };
    case actionTypes.SHOW_ALERT_MODAL:
      return {
        ...state,
        showAlertModal: false,
        showHeaderHome: true
      };
    case actionTypes.SHOW_SEARCH_MODAL:
      return {
        ...state,
        showSearchModal: !state.showSearchModal
      };
    case actionTypes.SET_IS_SEARCHING:
      return {
        ...state,
        isSearching: true
      };
    case actionTypes.SHOW_PROVIDER_MODAL: {
      return {
        ...state,
        showProviderModal: !state.showProviderModal,
        showSellPointCard: false
      };
    }
    case actionTypes.END_SEARCH: {
      return {
        ...state,
        isSearching: false,
        isFromSearchPDV: false,
        sellPointSelected: null,
        showProviderModal: false
      };
    }
    case actionTypes.SHOW_CONGRATS_MODAL: {
      return {
        ...state,
        showCongratsModal: !state.showCongratsModal
      };
    }
    case actionTypes.SHOW_SEARCH_PDV_MODAL:
      return {
        ...state,
        showSearchPDVModal: !state.showSearchPDVModal
      };
    case actionTypes.SET_PDV_FROM_SEARCH:
      return setPDVSelectedFromSearch({ ...state }, action.payload);
    case actionTypes.IS_FROM_SEARCH_PDV:
      return {
        ...state,
        isFromSearchPDV: false
      };
    case actionTypes.SHOW_REQ_LOADING:
      return {
        ...state,
        showReqLoading: !state.showReqLoading
      };
    case actionTypes.SET_THEME:
      return {
        ...state,
        theme: action.payload
      };
    case actionTypes.SHOW_ONBOARDING_MODAL:
      return {
        ...state,
        showOnboardingModal: false,
    };
    default:
      return state;
  }
};
