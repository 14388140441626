import React, { useState } from "react";
import { connect } from "react-redux";
import styles from "./MainPage.module.scss";
import Map from "../../components/Map/Map";
import Footer from "../../components/Footer/Footer";
import AlertModal from "../../components/UI/Alert/Alert";
import HeaderHome from "../../components/UI/HeaderHome/HeaderHome";
import { showAlertModalHandler } from "../../store/generalActions";
import HowItWorks from "../../components/HowItWorks/HowItWorks";
import SellPointCard from "../../components/SellPointModal/SellPointModal";
import SearchProducts from "../../components/SearchProducts/SearchProducts";
import Onbording from "../../components/Onbording/Onboarding";
import Search from "../../components/Search/Search";

import Home from "../../components/Home/Home";
import ProviderProducts from "../../components/ProvidersProducts/ProviderProducts";
import FinishSurveyModal from "../../components/FinishSurveyModal/FinishSurveyModal";
import SearchPDV from "../../components/SearchPDV/SearchPDV";
import { useMediaQuery } from "react-responsive";
const MainPage = ({ showOnboardingModal,userLocation, showAlertModal, showHeaderHome, showSearchModal, showSellPointCard, showProviderModal, showCongratsModal, showSearchPDVModal }) => {
  const { lat, lng } = userLocation;
  const [triggerHowItWorks, setTriggerHowItWorks] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 900px)" });

  const hasToken = localStorage.getItem("token");
  const handleHowItWorks = () => {
    //esto es una prueba.
    setTriggerHowItWorks(!triggerHowItWorks);
  };
  return (<>
      <main className={styles.main}>
        {//showAlertModal ? <AlertModal /> : null
        }
        {isMobile ? <Search  isMobile={isMobile}/> :null}
        {//<HeaderHome handleHowItWorks={handleHowItWorks} showAlertModal={showAlertModal} />
        }

        {lat && lng ? <Map /> : <div>Loading...</div>}
        {showSellPointCard ? <SellPointCard /> : null}
        {//<Footer handleHowItWorks={handleHowItWorks} />
        <Home />
        }
        {triggerHowItWorks ? <HowItWorks active={triggerHowItWorks} close={handleHowItWorks} /> : null}
      </main>
      
      {showSearchPDVModal ?- <SearchPDV /> : null}
      {showSearchModal ? <SearchProducts /> : null}
      {showProviderModal ? <ProviderProducts /> : null}
      {showOnboardingModal && isMobile ? <Onbording /> : null}
      {/* ESTE ES EL MODAL DE GRACIAS POR COLABORAR */}
      {showCongratsModal ? <FinishSurveyModal opened={showCongratsModal} /> : null}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userLocation: state.userLocation,
    showAlertModal: state.showAlertModal,
    showHeaderHome: state.showHeaderHome,
    showSearchModal: state.showSearchModal,
    showSellPointCard: state.showSellPointCard,
    showProviderModal: state.showProviderModal,
    showCongratsModal: state.showCongratsModal,
    showSearchPDVModal: state.showSearchPDVModal,
    showOnboardingModal: state.showOnboardingModal
  };
};

export default connect(mapStateToProps, { showAlertModalHandler })(MainPage);
