import React, {useState} from 'react';


import SearchItems from './SearchItems/SearchItems'
import styles from './Search.module.scss';

import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import { ReactComponent as BurgerMenu } from "../../assets/burgerMenu.svg";


const data = [
    {data:"1 de septiembre villa ballester de septiembre villa ballester"},
    {data:"12 de septiembre villa ballester de septiembre villa ballester"},
    {data:"123 de septiembre villa ballester de septiembre villa ballester"},
    {data:"1234 de septiembre villa ballester de septiembre villa ballester"},
    {data:"12345 de septiembre villa ballester de septiembre villa ballester"},
    {data:"123456 de septiembre villa ballester de septiembre villa ballester"},
    {data:"a de septiembre villa ballester"},
    {data:"ab de septiembre villa ballester"},
    {data:"abc de septiembre villa ballester"},
    {data:"abcd de septiembre villa ballester"},
    {data:"abcde de septiembre villa ballester"}
]
const Search = (props) => {

    const [searchValue, setSerchValue] = useState('');
    const [isFocus, setIsFocus] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    
    const search = (e) => {
        setSerchValue(e.target.value)
        if(e.target.value != ''){
            setIsLoading(true)
        }
    }
    setTimeout(() => {
        setIsLoading(false)
    }, 600);

    const items = data.filter(item => item.data.includes(searchValue) && searchValue != '').slice(0,5) 

    let styleContainer = {}
    if(props.isMobile) {
        styleContainer = positionFixed
    }

    if(isFocus && props.isMobile){
        styleContainer = {...styleContainer,...searchingActive}
    }

    return (
        <div className={styles.searchContainer} style={styleContainer}>
            <div className={styles.searchContent} style={items.length || isLoading ? borderBottomRadius : null}>
                <figure>
                    <BurgerMenu />
                </figure>
                <input onFocus={() => setIsFocus(true)} onBlur={() => setIsFocus(false)} style={searchValue != ''  ? inputStyle : null} value={searchValue} placeholder="Ingresa tu dirección" onChange={(e)=> search(e)}/>
                <figure className={styles.searchIcon}>
                    <SearchIcon />
                </figure>
            </div>
            {items.length || isLoading  ? <SearchItems items={items} isLoading={isLoading}/> : null}
        </div>)
}

const borderBottomRadius = {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
}
const inputStyle = {
    textAlign:'start',
    paddingLeft:'1.5rem'
}
const positionFixed = {
    position:'fixed'
}
const searchingActive = {
    height:'100%',
    backgroundColor:'#fff',
    margin:'0',
    paddingTop:'2rem'
}

export default Search;
  