export const GET_PRODUCTS = "GET_PRODUCTS";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const SET_SELLPOINTS = "SET_SELLPOINTS";
export const SET_PROVIDER_PRODUCTS = "SET_PROVIDER_PRODUCTS";
export const SET_PDV = "SET_PDV";
export const SET_PDV_FROM_SEARCH = "SET_PDV_FROM_SEARCH";
export const CLOSE_PDV = "CLOSE_PDV";
export const SET_LOADING = "SET_LOADING";
export const SHOW_ALERT_MODAL = "SHOW_ALERT_MODAL";
export const SHOW_SEARCH_MODAL = "SHOW_SEARCH_MODAL";
export const SHOW_PROVIDER_MODAL = "SHOW_PROVIDER_MODAL";
export const HIDE_PROVIDER_MODAL = "HIDE_PROVIDER_MODAL";
export const SET_IS_SEARCHING = "SET_IS_SEARCHING";
export const END_SEARCH = "END_SEARCH";
export const SHOW_CONGRATS_MODAL = "SHOW_CONGRATS_MODAL";
export const SHOW_SEARCH_PDV_MODAL = "SHOW_SEARCH_PDV_MODAL";
export const IS_FROM_SEARCH_PDV = "IS_FROM_SEARCH_PDV";
export const SHOW_REQ_LOADING = "SHOW_REQ_LOADING";
export const SET_THEME = "SET_THEME"; 
export const SHOW_ONBOARDING_MODAL = "SHOW_ONBOARDING_MODAL";
