import React from 'react';
import { useMediaQuery } from "react-responsive";

import styles from './Home.module.scss';
import Search from "../../components/Search/Search";
import img1 from '../../assets/buscarPro.svg'
import img2 from '../../assets/retirarDinero.svg'
import img3 from '../../assets/pedidosOnline.svg'


const Home = (props) => {

    const isMobile = useMediaQuery({ query: "(max-width: 900px)" });
    return (
        <div className={styles.homeContainer} >
            <div className={styles.homeTitle}><label>infocovid.online</label></div>
            {isMobile ? null :<Search  />}
            <div className={styles.homeElement}></div>
            <div className={styles.homeMenu}>
                <div>
                    <img  src={img1} height="70%"/>
                    <p>Buscar productos</p>
                </div>
                <div>
                    <img  src={img2} height="70%"/>
                    <p>Retirar Dinero</p>
                </div>
                <div>
                    <img  src={img3}  height="70%"/>
                    <p>Pedidos Online</p>
                </div>
            </div>
            <span>Hecho con <strong> &#10084; </strong>por paisanos</span>
        </div>
    )
}

export default Home;
  