import React, { useState, useEffect } from 'react';
import img1 from '../../../assets/onboardinstep1.svg'
import img2 from '../../../assets/onboardingstep2.svg'
import img3 from '../../../assets/onboardinstep3.svg'
import styles from '../Onboarding.module.scss';

const data = [
    { 
        contentBold:"Ayudanos a prevenir y preservar tu salud",
        content:"reduciendo el tiempo que estas expuesto en la via publica",
        img:img1
    },
    {
        contentBold:"Puedes buscar la disponibilidad de los productos que necesitas",
        content:" lugares donde retirar efectivo y hasta realizar pedidos online!",
        img:img2
    },
    {
        contentBold:"Recuerda actualizar la disponibilidad de productos que veas en los supermercados",
        content:" asi nos ayudamos entre todos!",
        img:img3
    }
]
const allowedTime = 150;

const Carousel = () => {
    
    const [sliceIndex, setSliceIndex] = useState(0);
    const [finishSlice, setfinishSlice] = useState('00');
    const [startTime, setStartTime] = useState(0);
    const [startX, setStartX] = useState(0);

    const swipedirStart = (e) => {
        setStartX(e.changedTouches[0].pageX)
        setStartTime(new Date().getTime())
    }

    const swipedirEnd = (e) => {
        let distX = startX - e.changedTouches[0].pageX
        let elapsedTime = new Date().getTime() - startTime
        if (elapsedTime >= allowedTime){ 
               let swipedir = (distX < 0)? -1 : 1
               setSliceIndex(sliceIndex + swipedir)
        }
    }

    if(sliceIndex == 3) {
        setSliceIndex(2)
        setfinishSlice('10')
        setTimeout(()=> {
            setfinishSlice('00')
        }
        ,150)
    }

    if(sliceIndex < 0) {
        setSliceIndex(0) 
    }

    const translate = { 
        transform: `translateX(-${sliceIndex}${finishSlice}%)` 
    };
    return (
            <div className={styles.carouselContainer}>
                <div className={styles.carouselItems}  onTouchStart={ (e) =>swipedirStart(e)} onTouchEnd={(e)=> swipedirEnd(e)}> 
                    {data.map((slice,index) => {
                        return (
                        <div key={"carousel"+index} style={translate}  className={styles.carouselItem}>
                            <div >
                                <img  src={slice.img}/>
                            </div>
                            <div>
                                <p><strong>{slice.contentBold}</strong>,{slice.content}</p>
                            </div>
                        </div>
                        )
                    })}
                </div>
                <div className={styles.itemsSelected}>
                    {new Array(data.length).fill(0).map((cero,index) => {
                        return (<div  onClick={() => setSliceIndex(index)} key={index} className={sliceIndex == index ? styles.itemSelected : styles.itemNoSelected} ></div>)
                    })}
                </div>
            </div>
    )
}

export default Carousel;