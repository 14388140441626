import React, {useState} from 'react';
import styles from '../Search.module.scss';
import { ReactComponent as Pdv } from "../../../assets/pdvi.svg";
import Lottie from "react-lottie";
import LoadingLottie from "../../../assets/loadingLottie.json";

const defaultOptions = {
  loop: true,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};


const SearchItems = (props) => {
    return ( props.isLoading ?    
        <div className={styles.searchItemsContainer}>  
            <div>
            <Lottie 
                style={{color:'#ddd'}} 
                options={{ animationData: LoadingLottie, ...defaultOptions }}
                width={50} height={50} />
            </div>     
        </div>:
        <div className={styles.searchItemsContainer}>
            {props.items.map((item,key) => {
                return (
                    <div key={key} className={styles.searchItemContent}>
                        <figure>
                            <Pdv />
                            <p>3 km</p>
                        </figure>
                        <label>{item.data}</label>   
                    </div>
                )
            })}
        </div>
    )
}
  
export default SearchItems;
  